//
// user.js
// Use this to write your custom JS
//

import { ModelViewerElement } from "@google/model-viewer";

// turn element or nodeList into an array
let makeArray = function (obj) {
    // use object if already an array
    if (Array.isArray(obj)) return obj;

    // return empty array if undefined or null. #6
    if (obj === null || obj === undefined) return [];

    let isArrayLike = typeof obj == 'object' && typeof obj.length == 'number';
    // convert nodeList to array
    if (isArrayLike) return [...obj];

    // array of single index
    return [obj];
};

var carousel = document.querySelector('.carousel');
if (carousel != null) {
    var flkty = new Flickity(carousel, {
        prevNextButtons: true,
        pageDots: true
    });
    // elements
    var cellsButtonGroup = document.querySelector('.button-group--cells');
    var cellsButtons = makeArray(cellsButtonGroup.children);

    let nameSelectedButton = "btn-primary-soft";
    let nameNotSelectedButton = "bg-gray-200";

    // update buttons on select
    flkty.on('select', function () {
        var previousSelectedButton = cellsButtonGroup.querySelector('.' + nameSelectedButton);
        var selectedButton = cellsButtonGroup.children[flkty.selectedIndex];

        previousSelectedButton.classList.remove(nameSelectedButton);
        previousSelectedButton.classList.add(nameNotSelectedButton);

        selectedButton.classList.add(nameSelectedButton);
        selectedButton.classList.remove(nameNotSelectedButton);
    });

    // Helper function to find ancestor
    function findAncestor(el, sel) {
        while ((el = el.parentElement) && !((el.matches || el.matchesSelector).call(el, sel)));
        return el;
    }

    // cell select
    cellsButtonGroup.addEventListener('click', function (event) {
        var btn = event.target.matches('.btn') ? event.target : findAncestor(event.target, '.btn');
        if (!btn) {
            return;
        }
        var index = cellsButtons.indexOf(btn);
        flkty.select(index);
    });
}


// Handles loading the events for <model-viewer>'s slotted progress bar
const onProgress = (event) => {
    const progressBar = event.target.querySelector('.progress-bar-ar');
    const updatingBar = event.target.querySelector('.update-bar');

    if (event.detail.totalProgress === 0) {
        progressBar.style.display = 'block';
        updatingBar.style.width = '0%';
    } else {
        updatingBar.style.width = `${event.detail.totalProgress * 100}%`;

        if (event.detail.totalProgress === 1) {
            setTimeout(() => {
                progressBar.style.display = 'none';
            }, 500);
        }
    }
};

// Select all radio buttons for selectiong the model
let radioButtons = document.querySelectorAll('input[name="btnradio"]');

let modelViewer = document.querySelector('model-viewer');

if (modelViewer) {
    modelViewer.addEventListener('progress', onProgress);

    // Add event listener to each radio button
    radioButtons.forEach((radioButton) => {
        radioButton.addEventListener('change', function () {
            if (this.id === 'btnradio-shelf') {
                modelViewer.src = '/assets/models/solid_wood_bookcase.glb';
                modelViewer.poster = '/assets/models/solid_wood_bookcase.webp';
            } else if (this.id === 'btnradio-kitchen') {
                modelViewer.src = '/assets/models/kitchen-scaled-rotated.glb';
                modelViewer.poster = '/assets/models/kitchen-scaled-rotated.webp';
            } else if (this.id === 'btnradio-bath') {
                modelViewer.src = '/assets/models/shower_cabin.glb';
                modelViewer.poster = '/assets/models/shower_cabin.webp';
            }
            else if (this.id === 'btnradio-apartment') {
                modelViewer.src = '/assets/models/apartment.glb';
                modelViewer.poster = '/assets/models/apartment.webp';
            }
        });
    });
}





